import { inject, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "ConfirmEmailView",
  mounted() {
    document.title = "Confirm Email";
  },
  data: () => ({
    alreadyVerified: false,
    done: false
  }),
  setup() {
    const route = useRoute();
    const API = inject("axios");
    const toast = inject("toast");
    const loading = ref(false);
    const verificationResend = async () => {
      loading.value = true;
      API.post("/auth/confirm/resend", {
        code: route.query.code,
        account: route.query.account
      }).then(() => {
        toast({
          text: "Verification email re-sent successfully"
        });
        loading.value = false;
      }).catch(err => {
        toast({
          text: "There was an error re-sending the verification email",
          type: "error"
        });
        console.log(err);
        loading.value = false;
      });
    };
    return {
      loading,
      verificationResend
    };
  },
  beforeCreate() {
    this.alreadyVerified = !!(this.$route.query?.q * 1);
    console.log(this.alreadyVerified);
    // this.$router.replace(this.$route.path);
  }
};