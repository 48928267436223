/* eslint-disable no-unused-vars */
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { isNil } from "lodash";
export default {
  name: "BaseAccordion",
  props: {
    modelValue: {
      type: [Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "update:accordionState"],
  setup(props, {
    emit
  }) {
    const acs = ref([]);
    const active = ref(null);
    const accordion = ref(null);
    onMounted(() => {
      const acs = [...accordion.value.querySelectorAll(":scope > .accordion__item")];
      if (!isNil(props.modelValue)) {
        if (Array.isArray(props.modelValue)) {
          /* prettier-ignore */
          props.modelValue.forEach(i => {
            acs[i].querySelector("input[type=checkbox]").checked = true;
            acs[i].classList.add("active");
            acs[i].querySelector(".accordion__header").classList.add("active");
            acs[i].querySelector(".accordion__content").classList.add("active");
          });
        } else {
          /* prettier-ignore */
          acs[props.modelValue].querySelector("input[type=checkbox]").checked = true;
          /* prettier-ignore */
          acs[props.modelValue].classList.add("active");
          /* prettier-ignore */
          acs[props.modelValue].querySelector(".accordion__header").classList.add("active");
          /* prettier-ignore */
          acs[props.modelValue].querySelector(".accordion__content").classList.add("active");
        }
      }
      acs.forEach((ac, i) => {
        const checkbox = ac.querySelector(":scope > input[type=checkbox]");
        const header = ac.querySelector(":scope > .accordion__header");
        const content = ac.querySelector(":scope > .accordion__content");
        if (props.disabled) {
          checkbox.value = false;
          checkbox.disabled = true;
          header.classList.add("disabled");
          content.classList.add("disabled");
          ac.classList.add("disabled");
          return;
        }
        checkbox.addEventListener("change", () => {
          if (checkbox.checked) {
            if (!props.multiple) {
              acs.forEach(ac2 => {
                /* prettier-ignore */
                ac2.querySelector("input[type=checkbox]").checked = false;
                /* prettier-ignore */
                ac2.classList.remove("active");
                /* prettier-ignore */
                ac2.querySelector(".accordion__header").classList.remove("active");
                /* prettier-ignore */
                ac2.querySelector(".accordion__content").classList.remove("active");
              });
            }
            checkbox.checked = true;
            header.classList.add("active");
            content.classList.add("active");
            ac.classList.add("active");
            let x = typeof props.modelValue != "object" ? [props.modelValue] : props.modelValue;
            emit("update:modelValue", props.multiple ? [...x, i] : i);
            emit("update:accordionState", props.multiple ? false : true);
          } else {
            checkbox.checked = false;
            header.classList.remove("active");
            content.classList.remove("active");
            ac.classList.remove("active");
            let x = typeof props.modelValue != "object" ? [props.modelValue] : props.modelValue;
            emit("update:modelValue", props.multiple ? x?.filter(v => v !== i) : null);
            emit("update:accordionState", props.multiple ? true : false);
          }
        });
      });
    });
    return {
      accordion,
      active,
      acs
    };
  }
};