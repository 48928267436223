import { string } from 'yup';
import API from './api';
import { debounce } from 'lodash';
const baseRules = string().email('Invalid email address').required('Email is required');
const debounceEmailCheck = debounce(async options => {
  const {
    email,
    accountID,
    IDNumber
  } = options.fields || {};
  const validBaseRules = await baseRules.validate(email, {
    abortEarly: true
  });
  if (!validBaseRules) return false;
  options.pending = true;
  try {
    const timeStart = Date.now();
    const response = await API.post('/users/check-email', {
      email,
      accountID,
      IDNumber
    });
    const timeEnd = Date.now();
    if (timeEnd - timeStart < 700) await new Promise(r => setTimeout(r, 700 - (timeEnd - timeStart)));
    options.pending = false;
    return response.data !== false;
  } catch (error) {
    options.pending = false;
    return "Error checking email in the system";
  }
}, 900, {
  leading: true,
  trailing: false
});

/**
 * 
 * @param {*} options - options for email rule
 * 
 * @param {string} options.fields.email - email to check
 * @param {string} options.fields.accountID - accountID to check against email
 * @param {string} options.fields.IDNumber - IDNumber to check against email
 * 
 * @param {boolean} options.pending - if the request is pending
 * 
 * @returns yup schema for email
 */
export const emailRule = options => {
  return baseRules.test('email', 'Email already exists', value => {
    return debounceEmailCheck({
      ...options,
      fields: {
        email: value,
        ...options?.fields
      }
    });
  });
};

/**
 * only intersect connect specific password rules
 * @returns yup schema for password
 */
export const passwordRule = () => string().required("Password is required").matches(/(?=(.*[a-z]){2,})/, {
  message: "Must contain at least 2 lowercase characters"
}).matches(/(?=(.*[A-Z]){1,})/, {
  message: "Must contain at least 1 uppercase letters"
}).matches(/(?=(.*[0-9]){1,})/, {
  message: "Must contain at least 1 digits"
}).matches(/(?=(.*[!@#$%^&*()\-__+.]){1,})/, {
  message: "Must contain at least 1 special character"
}).min(8, "Must be at least 8 characters long");