import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "flex flex-row justify-center text-center px-2",
  ref: "otp"
};
const _hoisted_2 = ["onUpdate:modelValue", "autofocus", "placeholder", "onKeydown"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.digits, (d, i) => {
    return _withDirectives((_openBlock(), _createElementBlock("input", {
      "onUpdate:modelValue": $event => $setup.digits[i] = $event,
      autofocus: i == 0,
      placeholder: i + 1,
      key: i,
      class: "m-2 border-2 border-primary-500 h-12 w-10 text-center font-semibold form-control rounded focus-visible:outline-secondary-500 transition-all duration-300 ease-in-out text-lg text-darkprimary-500",
      type: "text",
      maxlength: "1",
      onKeydown: $event => $setup.handleKeyDown(i, $event),
      onPaste: _cache[0] || (_cache[0] = _withModifiers($event => $setup.handlePaste($event), ["prevent"]))
    }, null, 40, _hoisted_2)), [[_vModelText, $setup.digits[i]]]);
  }), 128))], 512);
}