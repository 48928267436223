import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import API from '@/pages/shared/services/api';
import '@/pages/shared/styles/input.css';
import alertPlugin from '@/pages/shared/plugins/toast';
import '@vuepic/vue-datepicker/dist/main.css';
import { VTooltip, VClosePopper } from 'floating-vue';
import 'floating-vue/dist/style.css';
const app = createApp(App);
app.use(alertPlugin, {});
app.config.globalProperties.$api = API;
app.provide('axios', app.config.globalProperties.$api);
app.directive('tooltip', VTooltip);
app.directive('close-popper', VClosePopper);
app.use(router).mount('#app');