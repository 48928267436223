import { computed, inject, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { string } from "yup";
import { Form } from "vee-validate";
import { mask } from "vue-the-mask";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { useRoute, useRouter } from "vue-router";
import { generateXeroToken } from "@/pages/shared/services/xero";
export default {
  components: {
    Form
  },
  directives: {
    mask
  },
  mounted() {
    document.title = "Register";
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const API = inject("axios");
    const toast = inject("toast");
    const route = useRoute();
    const step = ref(0);
    const tutorialModal = ref(false);
    const resendAccount = ref(null);
    let initialForm = {
      user: {},
      verify: {},
      saID: true,
      existing: false
    };
    const passwordVisible = ref(false);
    const user = computed(() => store.getters["auth/user"]);
    const profile = computed(() => store.getters["companies/getCompany"]);
    const loading1 = ref(false),
      loading2 = ref(false),
      startOnboardingModal = ref(false);
    const form = reactive(initialForm);
    const registerForm = ref(null);
    const nextClicked = async () => {
      const isValid = await registerForm.value.validate();
      if (step.value == 0) {
        if (!isValid.valid) {
          toast({
            text: "Please fill in all required fields",
            type: "error"
          });
          step.value = 0;
          return false;
        } else if (form.existing && isValid.valid) {
          loading1.value = true;
          let loginStatus = false;
          await API.post("/auth/login", {
            email: form.user.email,
            password: form.user.password,
            skip2fa: true
          }).then(({
            data
          }) => {
            form.token = data.token;
            step.value = 1;
            loginStatus = step.value > 0;
          }).catch(err => {
            console.log(err.response.data);
            if (err.response?.data?.account) resendAccount.value = err.response.data;else toast({
              text: err.response?.data?.msg,
              type: "error"
            });
          }).finally(() => {
            loading1.value = false;
          });
          return loginStatus;
        } else if (form.saID && !form.user.IDVerified) {
          registerForm.value.setErrors({
            "form.user.IDNumber": "Please verify your ID number"
          });

          // toast({
          // 	text: "Please verify your ID number",
          // 	type: "error",
          // });
          return false;
        } else if (isValid.valid) {
          step.value++;
          return true;
        }
      }
    };
    const checkingEmail = ref(false);

    /*** company search ***/
    const searchName = ref("");
    const searchReg = ref("");
    const searchBy = ref(2);
    const searchResults = ref(null);
    const selectedCompany = ref(null);
    const requiredRules = string().required("This field is required");
    const idRules = requiredRules.matches(/(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/, {
      message: "Not a valid South African ID number",
      excludeEmptyString: true
    });
    const emailRules1 = requiredRules.email("Email address not valid");

    // let timeoutForCheckingTheEmail;
    const emailRules = requiredRules.email("Email address not valid");
    const passwordRules = requiredRules.matches(/(?=(.*[a-z]){2,})/, {
      message: "Must contain at least 2 lowercase characters"
    }).matches(/(?=(.*[A-Z]){1,})/, {
      message: "Must contain at least 1 uppercase letters"
    }).matches(/(?=(.*[0-9]){1,})/, {
      message: "Must contain at least 1 digits"
    }).matches(/(?=(.*[!@#$%^&*()\-__+.]){1,})/, {
      message: "Must contain at least 1 special character"
    }).min(8, "Must be at least 8 characters long");
    const passwordRules2 = requiredRules.test("password", "Passwords do not match", value => {
      return value === form.user.password;
    });
    const attemptDateOfBirth = idNumber => {
      const dob = idNumber.substring(0, 6);
      try {
        const parsed = parse(dob, "yyMMdd", new Date());
        form.user.DOB = format(parsed, "yyyy-MM-dd");
      } catch (error) {
        console.log(error);
      }
    };
    const searchCompany = () => {
      loading1.value = true;
      selectedCompany.value = null;
      API.post("/onboarding/cipc/company_search", {
        CipcSearchBy: "4",
        CompanyName: searchName.value,
        RegistrationNumber: searchReg.value,
        Reference: "Intersect Company Search"
      }).then(res => {
        searchResults.value = res.data ?? [];
      }).catch(err => {
        toast({
          text: err.response.data,
          type: "error"
        });
      }).finally(() => {
        loading1.value = false;
      });
    };
    const companySelected = r => {
      selectedCompany.value = r;
      if (selectedCompany.value) {
        // check if registration number is end with '23'
        /* prettier-ignore */
        if (selectedCompany.value?.CompanyRegistrationNumber?.endsWith("23")) {
          notAllowedModal.value = true;
        }
      } else {
        toast({
          text: "Please select a company",
          type: "error"
        });
      }
    };
    const verifyID = async () => {
      const isValid = await registerForm.value.validateField("form.user.IDNumber");
      if (!isValid.valid) return;
      loading1.value = true;
      API.post(`/users/verify_id`, {
        idNumber: form.user.IDNumber
      }).then(() => {
        form.user.IDVerified = true;
        registerForm.value.setFieldError("form.user.IDNumber", null);
        toast({
          text: "ID number verified"
        });
      }).catch(() => {
        console.log("error");
        toast({
          text: "Couldn't verify this ID number",
          type: "error"
        });
      }).finally(() => {
        loading1.value = false;
      });
    };
    watch(tutorialModal, v => {
      let video = document.querySelector("video");
      if (!v && video) {
        video.pause();
        video.currentTime = 0;
      } else if (v && video) {
        video.play();
      }
    });
    const resendVerificationEmail = () => {
      resendAccount.value["loading"] = true;
      API.post("/auth/confirm/resend", {
        ...resendAccount.value
      }).then(() => {
        toast({
          text: "Verification email re-sent successfully"
        });
        resendAccount.value = null;
      }).catch(err => {
        toast({
          text: "There was an error re-sending the verification email",
          type: "error"
        });
        console.log(err);
      }).finally(() => {
        resendAccount.value["loading"] = false;
      });
    };
    const subscriptionType = ref("BUSINESS");
    const planRedirect = computed(() => Boolean(route.query?.planRedirect));
    const plan = computed(() => (route.query?.plan ?? 0) * 1);
    const planName = computed(() => route.query?.planName);
    watch(() => route.query, v => v?.planRedirect && (subscriptionType.value = v?.subscriptionType), {
      immediate: true,
      deep: true
    });
    const registerUser = async () => {
      const isValid = await registerForm.value.validate();
      if (isValid.valid) {
        loading2.value = true;
        API.post("/users/company/register", {
          ...form,
          /* prettier-ignore */
          plan: plan.value ? {
            plan: plan.value,
            subscriptionType: subscriptionType.value
          } : undefined
        }).then(() => {
          router.push("/");
          toast({
            text: "Please verify your email address to finish user registration.",
            timeout: 20000
          });
        }).catch(err => {
          toast({
            text: err.response?.data?.msg ?? "Something went wrong",
            type: "error"
          });
          loading2.value = false;
        }).finally(() => {
          loading2.value = false;
        });
      } else {
        toast({
          text: "Please fill in all required fields",
          type: "error"
        });
      }
    };

    // modal for not-allowed registration
    const notAllowedModal = ref(false);
    const registerCompany = async () => {
      const isValid = await registerForm.value.validate();
      if (isValid.valid) {
        loading2.value = true;
        API.post("/users/company/register", {
          ...form,
          company: selectedCompany.value,
          /* prettier-ignore */
          plan: plan.value ? {
            plan: plan.value,
            subscriptionType: subscriptionType.value
          } : undefined
        }).then(data => {
          router.push("/");
          toast({
            text: data.existing_account ? "Company registration has been initiated. This process will take approximately 2 - 30 minutes, then you can login to start using your company." : "Please verify your email address to initiate and finish the company registration. Registration process will take approximately 2 - 30 minutes after email verification",
            timeout: 20000
          });
        }).catch(err => {
          toast({
            text: err.response?.data?.msg ?? "Something went wrong",
            type: "error"
          });
          loading2.value = false;
        }).finally(() => {
          loading2.value = false;
        });
      } else {
        toast({
          text: "Please fill in all required fields",
          type: "error"
        });
      }
    };
    const xeroAuthURL = computed(() => {
      let stateData = {};
      if (plan.value) stateData["plan"] = {
        plan: plan.value,
        subscriptionType: subscriptionType.value,
        planName: planName.value
      };
      return generateXeroToken("/login/xero/signup", stateData, subscriptionType.value === "CONSULTANT");
    });
    console.log(xeroAuthURL.value);
    return {
      nextClicked,
      registerForm,
      registerCompany,
      form,
      step,
      user,
      profile,
      searchBy,
      searchName,
      searchReg,
      searchResults,
      searchCompany,
      requiredRules,
      selectedCompany,
      loading1,
      loading2,
      startOnboardingModal,
      attemptDateOfBirth,
      idRules,
      emailRules,
      passwordRules,
      passwordRules2,
      toast,
      verifyID,
      passwordVisible,
      checkingEmail,
      emailRules1,
      xeroAuthURL,
      tutorialModal,
      resendVerificationEmail,
      resendAccount,
      plan,
      planName,
      planRedirect,
      subscriptionType,
      registerUser,
      companySelected,
      notAllowedModal
    };
  }
};