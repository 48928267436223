import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3954fe8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps({
    class: ["collapse collapse-plus border accordion__item", {
      'disabled': $props.disabled
    }],
    ref: "accordionItem"
  }, _ctx.$attrs), [_createElementVNode("input", {
    type: "checkbox",
    class: "peer",
    disabled: $props.disabled
  }, null, 8, _hoisted_1), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 16);
}