import { useField } from "vee-validate";
import { toRef, watch } from "vue";
export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    modelValue: {},
    value: {},
    label: String,
    customPlaceholder: String,
    errorMessage: {
      type: String
    },
    warningMessage: {
      type: String
    },
    successMessage: {
      type: String
    },
    noDetails: Boolean,
    hint: String,
    refKey: {
      type: String,
      default: ""
    },
    rules: [String, Object, Array],
    immediate: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    active: {
      type: Boolean
    },
    disabled: {},
    readonly: {
      type: Boolean
    },
    autocomplete: {},
    align: {
      type: String,
      default: "left"
    }
  },
  setup(props, {
    emit,
    attrs
  }) {
    const {
      value: inputValue,
      errorMessage: error,
      handleBlur,
      handleChange,
      resetField,
      meta
    } = useField(toRef(props, "refKey"), props.rules ?? null, {
      initialValue: props.modelValue ?? props.value,
      validateOnMount: props.immediate,
      validateOnValueUpdate: props.immediate
      // standalone: !!props.rules,
    });
    resetField();
    watch(inputValue, val => {
      if (!(attrs.readonly == "" || attrs.readonly) && props.disabled == false && props.readonly == false) {
        emit("update:modelValue", val);
      }
    });

    // to fully support two-way binding you sync the vee-validate ref when the prop changes
    watch(() => props.modelValue, v => v !== inputValue.value && (inputValue.value = v));
    return {
      inputValue,
      error,
      handleBlur,
      handleChange,
      meta
    };
  },
  emits: ["update:modelValue", "input", "blur", "focus", "change", "keydown", "keyup", "keypress"],
  methods: {
    valChange(eventName, event) {
      this.$emit(eventName, event?.target?.value);
      !this.$props.readonly && this.$emit("update:modelValue", event?.target?.value);
    }
  },
  watch: {}
};