import "core-js/modules/web.dom-exception.stack.js";
import pkceChallenge, { generateChallenge } from "pkce-challenge";
const generateXeroToken = (redirect_endpoint, state_data = "", profileOnly = false) => {
  let codeVerifier = localStorage.getItem("code_verifier");
  let codeChallenge = generateChallenge(codeVerifier);
  let authURL = '';
  if (localStorage.getItem("code_verifier")) {
    codeVerifier = localStorage.getItem("code_verifier");
    codeChallenge = generateChallenge(codeVerifier);
  } else {
    let ch = pkceChallenge();
    codeVerifier = ch.code_verifier;
    codeChallenge = ch.code_challenge;
    localStorage.setItem("code_verifier", codeVerifier);
  }
  const scope = profileOnly ? "openid profile email offline_access" : "openid profile email accounting.transactions offline_access accounting.settings accounting.contacts accounting.reports.read";
  const qParams = new URLSearchParams({
    response_type: "code",
    client_id: process.env.VUE_APP_XERO_CLIENT_ID,
    redirect_uri: window.location.origin + redirect_endpoint,
    scope: scope,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    state: btoa(JSON.stringify(state_data))
  });
  authURL = `https://login.xero.com/identity/connect/authorize?${qParams.toString()}`;
  return authURL;
};
export { generateXeroToken };