import { inject, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { string } from "yup";
import { Form } from "vee-validate";
export default {
  name: "PasswordResetView",
  components: {
    Form
  },
  mounted() {
    document.title = "Reset Password";
  },
  setup() {
    const isValid = ref(false);
    const API = inject("axios");
    const tokenData = ref(null);
    const route = useRoute();
    const router = useRouter();
    const toast = inject("toast");
    const password = ref("");
    const passwordConfirm = ref("");
    const passwordRule = string().required("Password is required").min(8, "Password must be at least 8 characters").matches(/(?=(.*[a-z]){2,})/, {
      message: "Must contain at least 2 lowercase characters"
    }).matches(/(?=(.*[A-Z]){1,})/, {
      message: "Must contain at least 1 uppercase letters"
    }).matches(/(?=(.*[0-9]){1,})/, {
      message: "Must contain at least 1 digits"
    }).matches(/(?=(.*[!@#$%^&*()\-__+.]){1,})/, {
      message: "Must contain at least 1 special character"
    }).min(8, "Must be at least 8 characters long");
    const passwordConfirmRule = string().required("Password is required").test("password", "Passwords do not match", value => {
      return value === password.value;
    });
    const passwordVisible = ref(false);
    router;
    API.get(`/global/checktoken?token=${route.query?.token}&return=1`).then(res => {
      console.log(res.data);
      tokenData.value = res.data;
    });
    const loading = ref(false);
    const resetForm = ref(null);
    const resetPassword = async () => {
      const isValid = await resetForm.value.validate();
      if (!isValid.valid) return;
      loading.value = true;
      API.post("/users/password-reset", {
        password: password.value,
        accountID: tokenData.value.accountID,
        token: tokenData.value?.token
      }).then(res => {
        console.log(res);
        loading.value = false;
        router.push({
          path: "/",
          query: {
            msg: "Password reset successfully. Please login to continue."
          }
        });
      }).catch(err => {
        console.log(err);
        if (err.response?.status === 410) {
          return router.push({
            path: "/",
            query: {
              msg: "Invalid reset link. Link may have expired. Please request a new one."
            }
          });
        }
        toast({
          text: err.response?.data?.msg,
          type: "error"
        });
        loading.value = false;
      });
    };
    return {
      isValid,
      tokenData,
      passwordRule,
      passwordConfirmRule,
      password,
      passwordConfirm,
      passwordVisible,
      resetForm,
      loading,
      resetPassword
    };
  }
};