import { ref } from "vue";
export default {
  name: "BaseOTP",
  emits: ["update:otp"],
  setup(props, {
    emit
  }) {
    const otp = ref(null);
    const digits = ref(new Array(6).fill(null));
    const allDigitsFilled = () => digits.value.every(d => d !== null && d !== undefined);
    const handleKeyDown = async (i, ev) => {
      if (!["Tab", "ArrowLeft", "ArrowRight"].includes(ev.key)) ev.preventDefault();
      if (ev.key === "Backspace") {
        digits.value[i] = null;
        if (i > 0) otp.value.children[i - 1].focus();
      } else if (ev.key === "ArrowLeft") {
        if (i > 0) otp.value.children[i - 1].focus();
      } else if (ev.key === "ArrowRight" && digits.value[i + 1] !== null) {
        if (i < 5) otp.value.children[i + 1].focus();
      }
      if (/^([0-9])$/.test(ev.key)) {
        digits.value[i] = ev.key;
        if (i < 5) otp.value.children[i + 1].focus();
      }
      ev = ev || window.event;
      var key = ev.which || ev.keyCode;
      let ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;
      if (ctrl && ev.key === "v") {
        await navigator.clipboard.readText().then(text => {
          if (/^([0-9]{6})$/.test(text)) {
            digits.value = text.split("");
            otp.value.children[5].focus();
          }
        });
      }
      if (allDigitsFilled()) {
        emit("update:otp", digits.value.join(""));
      } else {
        emit("update:otp", null);
      }
    };
    const handlePaste = ev => {
      const pasted = ev.clipboardData.getData("text");
      if (/^([0-9]{6})$/.test(pasted)) {
        digits.value = pasted.split("");
        otp.value.children[5].focus();
      }
    };
    return {
      otp,
      digits,
      handleKeyDown,
      handlePaste
    };
  }
};