import "core-js/modules/web.dom-exception.stack.js";
import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/pages/login/views/LoginView.vue';
import LogoutView from '@/pages/login/views/LogoutView.vue';
import RegisterView from '@/pages/login/views/RegisterView.vue';
import ConfirmEmailView from '@/pages/login/views/ConfirmEmailView.vue';
import PasswordResetView from '@/pages/login/views/PasswordResetView.vue';
import { generateXeroToken } from "@/pages/shared/services/xero";
import API from '@/pages/shared/services/api';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
let store = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, memoryDriver._driver],
  name: 'pre_imcp'
});

// create store on winow object
window.pre_imcp = store;
const routes = [{
  path: '/:name?',
  name: 'Login',
  component: LoginView,
  beforeEnter: async to => {
    try {
      let token = Cookies.get('access');
      if (!to.params.name && isEmpty(to.query) && token) {
        let loginURL = new URL(process.env.VUE_APP_APP_URL);
        loginURL.searchParams.set("access", token);
        window.location.href = loginURL.href;
      }
    } catch (error) {
      return true;
    }
    return true;
  }
}, {
  path: '/confirm/:valid?',
  name: 'Confirm Email',
  component: ConfirmEmailView,
  beforeEnter: async to => {
    if (to.query?.code && to.query?.account && !to.params.valid) {
      return await API.post(`/auth/confirm`, {
        code: to.query.code,
        account: to.query.account
      }).then(() => true).catch(err => {
        console.log({
          path: `${to.path}/1`,
          query: {
            ...to.query,
            error: err.response?.data?.msg
          }
        });
        if (err.response?.status == 410) return {
          path: `${to.path}/1`,
          query: {
            q: 1
          }
        };else if (err.response?.status == 400) return {
          path: `${to.path}/1`,
          query: {
            ...to.query,
            error: err.response?.data?.msg
          }
        };else return {
          path: '/',
          query: {
            error: 'Something went wrong'
          }
        };
      });
    } else return true;
  }
}, {
  path: '/register/:type?',
  name: 'Register',
  component: RegisterView,
  beforeEnter: async to => {
    if (to.params?.type == "xero") {
      await window.pre_imcp.clear();
      const xeroAuthURL = generateXeroToken("/login/xero/signup", {}, true);
      window.location.href = xeroAuthURL;
      return false;
    }
    if (!to.query.plan && !to.query.subscriptionType) return {
      path: '/subscribe'
    };
    return true;
  }
}, {
  path: '/subscribe',
  name: 'Subscribe',
  component: () => import('@/pages/login/views/SubscribeView.vue')
}, {
  path: '/password_setup',
  name: 'Password Setup',
  component: () => import('@/pages/login/views/PasswordSetupView.vue'),
  beforeEnter: async to => {
    // to;
    // return true;
    if (!to.query.token) return {
      path: '/',
      query: {
        error: 'Invalid password setup link. Link may have expired.'
      }
    };
    return await API.get(`/global/checktoken?token=${to.query.token}`).then(res => {
      if (res.status == 200) return true;
    }).catch(() => {
      return {
        path: '/',
        query: {
          error: 'Invalid password setup link. Link may have expired.'
        }
      };
    });
  }
}, {
  path: '/logout',
  name: 'Logout',
  component: LogoutView
}, {
  path: '/xero',
  name: 'Xero Login',
  beforeEnter: async to => {
    if (to.query.error) return {
      path: '/',
      query: {
        error: to.query.error
      }
    };
    // check for oauth2 default query params
    else if (to.query.code && to.query.state) {
      return await API.post(`/auth/xero/login`, {
        code: to.query.code,
        state: to.query.state,
        code_verifier: localStorage.getItem('code_verifier')
      }).then(({
        data
      }) => {
        window.location.href = `${process.env.VUE_APP_APP_URL}?access=${data.token}`;
        return true;
      }).catch(() => {
        return {
          path: '/',
          query: {
            error: 'You do not have a Xero account. Please register.'
          }
        };
      });
    } else return {
      path: '/',
      query: {
        error: 'Something went wrong'
      }
    };
  }
}, {
  path: '/xero/signup',
  name: 'Xero',
  props: {
    xero: {}
  },
  component: () => import('@/pages/login/views/XeroSignUpView.vue'),
  beforeEnter: async to => {
    if (to.query.error) return {
      path: '/',
      query: {
        error: to.query.error
      }
    };
    // check for oauth2 default query params
    else if (to.query.code && to.query.state) {
      let stateData;
      try {
        stateData = JSON.parse(atob(to.query.state));
      } catch (e) {
        return {
          name: 'Subscribe'
        };
      }
      return await API.post(`/auth/xero/signup`, {
        code: to.query.code,
        state: to.query.state,
        code_verifier: localStorage.getItem('code_verifier'),
        subscriptionType: stateData?.plan?.subscriptionType ?? "BUSINESS"
      }).then(({
        data
      }) => {
        // add 5 minutes to current time to get expiry time
        let x = {
          data: data.tenants,
          code: data.code,
          auth_user: data.auth_user,
          expires_at: new Date(new Date().getTime() + 30 * 60 * 1000).getTime(),
          state: stateData
        };
        return store.setItem('xero', x).then(() => {
          return {
            path: to.path
          };
        });
      }).catch(err => {
        return store.removeItem('xero').then(() => {
          return {
            path: '/',
            query: {
              error: err.response?.data?.msg || 'Something went wrong'
            }
          };
        });
      });
    } else {
      return true;
    }
  }
}, {
  path: '/reset-password',
  name: 'Reset Password',
  component: PasswordResetView,
  beforeEnter: async to => {
    return await API.get(`/global/checktoken?token=${to.query.token}`).then(res => {
      if (res.status == 200) return true;
    }).catch(() => {
      return {
        path: '/',
        query: {
          error: 'Invalid password reset link. Link may have expired.'
        }
      };
    });
  }
}];
// component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')

const router = createRouter({
  history: createWebHistory('/login/'),
  routes
});
export default router;