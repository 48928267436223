// import { defineAsyncComponent } from "vue";
// import LoginView from "./views/LoginView.vue";

export default {
  name: "App",
  components: {
    // LoginView
  },
  data: () => ({
    //
  })
};